import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class WhyChooseUsV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="cta-three  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex">
              <div className="my-auto">
                <div className="cta-three__image">
                  <img
                    src={publicUrl + "assets/images/shapes/cta-3-1-shape.png"}
                    alt={imagealt}
                    className="cta-three__image-shape"
                  />
                  <img
                    src={publicUrl + "assets/images/resources/cta-3-1.jpg"}
                    alt={imagealt}
                  />
                </div>
                {/* /.cta-three__image */}
              </div>
              {/* /.my-auto */}
            </div>
            {/* /.col-lg-7 */}
            <div className="col-lg-5">
              <div className="cta-three__content go-top">
                <div className="block-title-two text-left">
                  <p>¿Por qué elegirnos?</p>
                  <h3>
                    Somos la Agencia <br /> de Soluciones Exclusivas
                  </h3>
                </div>
                {/* /.block-title-two */}
                <div className="cta-three__box-wrap">
                  <div className="cta-three__box">
                    <div className="cta-three__box-icon">
                      <i className="far fa-shield-alt" />
                    </div>
                    {/* /.cta-three__icon */}
                    <div className="cta-three__box-content">
                      <h3>Estrategia de Seguridad</h3>
                      <p>
                        Protegemos tu privacidad y datos con tecnología avanzada
                        de <strong>Secure Data Protection (SDP)</strong>.
                      </p>
                    </div>
                    {/* /.cta-three__box-content */}
                  </div>
                  {/* /.cta-three__box */}
                  <div className="cta-three__box">
                    <div className="cta-three__box-icon">
                      <i className="far fa-chart-line" />
                    </div>
                    {/* /.cta-three__icon */}
                    <div className="cta-three__box-content">
                      <h3>Crecimiento Empresarial</h3>
                      <p>
                        Conectamos tu negocio con soluciones innovadoras que
                        aseguran un desarrollo sólido y confiable.
                      </p>
                    </div>
                    {/* /.cta-three__box-content */}
                  </div>
                  {/* /.cta-three__box */}
                  <div className="cta-three__box">
                    <div className="cta-three__box-icon">
                      <i className="fa fa-mobile-alt" />
                    </div>
                    {/* /.cta-three__icon */}
                    <div className="cta-three__box-content">
                      <h3>Protección de tu Identidad</h3>
                      <p>
                        Gracias a SDP, prevenimos clonación de SIM y aseguramos
                        la autenticación en nuestras redes.
                      </p>
                    </div>
                    {/* /.cta-three__box-content */}
                  </div>
                  {/* /.cta-three__box */}
                </div>
                {/* /.cta-three__box-wrap */}
                <Link to="/" className="thm-btn cta-three__btn">
                  Comienza Ahora <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn cta-three__btn */}
              </div>
              {/* /.cta-three__content */}
            </div>
            {/* /.col-lg-5 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default WhyChooseUsV2;
