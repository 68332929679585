import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavbarV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    return (
      <header className="site-header-two site-header-two__ go-top  go-top">
        <nav className="main-nav__two stricky">
          <div className="container">
            <div className="main-nav__logo-box">
              <Link to="/">
                <img
                  src={publicUrl + "assets/images/logo-2-1.png"}
                  alt={imgattr}
                />
              </Link>
            </div>
            {/* /.main-nav__logo-box */}
            <div className="main-nav__main-navigation">
              <ul className=" main-nav__navigation-box">
                <li>
                  <Link to="/">Inicio</Link>
                </li>
                <li className="dropdown">
                  <a href="#">Consultar</a>
                  <ul>
                    <li>
                      <Link to="/">Cobertura</Link>
                    </li>
                    <li>
                      <Link to="/">Compatibilidad</Link>
                    </li>
                    <li>
                      <Link to="/">Estatus de Envio</Link>
                    </li>
                    <li>
                      <Link to="/">Preguntas Frecuentes</Link>
                    </li>
                    <li>
                      <Link to="/">Quienes Somos</Link>
                    </li>
                  </ul>
                  {/* /.sub-menu */}
                </li>
                <li>
                  <Link to="/">Portabilidad</Link>
                </li>
                <li>
                  <Link to="/">Recarga</Link>
                </li>
                <li>
                  <Link to="/">Contacto</Link>
                </li>
                <li>
                  <Link to="/">Contacto</Link>
                </li>
              </ul>
            </div>
            {/* /.main-nav__main-navigation */}
            <div className="main-nav__right">
              <a href="#" className="side-menu__toggler">
                <span />
              </a>
            </div>
            {/* /.main-nav__right */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.main-nav__one */}
      </header>
    );
  }
}

export default NavbarV2;
