import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class BannerV2 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const cssUrl = publicUrl + "assets/css/color-2.css";
    this.addStyle(cssUrl);

    const $ = window.$;
    if ($(".thm__owl-carousel").length) {
      $(".thm__owl-carousel").each(function () {
        var Self = $(this);
        var carouselOptions = Self.data("options");
        var carouselPrevSelector = Self.data("carousel-prev-btn");
        var carouselNextSelector = Self.data("carousel-next-btn");
        var thmCarousel = Self.owlCarousel(carouselOptions);
        if (carouselPrevSelector !== undefined) {
          $(carouselPrevSelector).on("click", function () {
            thmCarousel.trigger("prev.owl.carousel");
            return false;
          });
        }
        if (carouselNextSelector !== undefined) {
          $(carouselNextSelector).on("click", function () {
            thmCarousel.trigger("next.owl.carousel");
            return false;
          });
        }
      });
    }
  }

  addStyle = (url) => {
    const style = document.createElement("link");
    style.href = url;
    style.rel = "stylesheet";
    style.async = true;

    document.head.appendChild(style);
  };
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="banner-two  go-top">
        <img
          src={publicUrl + "assets/images/shapes/banner-shape-1-1.png"}
          alt="Banner"
          className="banner-two__moc"
        />
        <div
          className="banner-two__image-carousel owl-carousel owl-theme thm__owl-carousel"
          data-options='{"loop": true, "items": 1, "margin": 0, "dots": false, "nav": false, "animateOut": "slideOutDown", "animateIn": "fadeIn", "active": true, "smartSpeed": 1000, "autoplay": true, "autoplayTimeout": 7000, "autoplayHoverPause": false}'
        >
          <div className="item">
            <img
              src={publicUrl + "assets/images/resources/banner-2-1.jpg"}
              alt="Banner"
            />
          </div>
          {/* /.item */}
          <div className="item">
            <img
              src={publicUrl + "assets/images/resources/banner-2-2.jpg"}
              alt="Banner"
            />
          </div>
          {/* /.item */}
        </div>
        {/* /.banner-two__image-carousel */}
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="banner-two__content">
                <h3>
                  <span>X-Cel </span> <br />
                  Secure <br /> Data <em>Protection</em>
                </h3>
                <p>Protección del cifrado de llamadas y mensajes SMS.</p>
                <Link to="/" className="thm-btn banner-two__btn">
                  Pedir ahora <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn */}
              </div>
              {/* /.banner-two__content */}
            </div>
            {/* /.col-xl-7 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default BannerV2;
