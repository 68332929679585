import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Service extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section
        className="service-two"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/images/shapes/dotted-patter-1.png)",
        }}
      >
        <div className="container go-top">
          <div className="row">
            <div className="col-lg-5 d-flex">
              <div className="my-auto">
                <div className="service-two__block">
                  <div className="block-title-two text-left">
                    <p>Nuestros Servicios</p>
                    <h3>
                      Brindamos los Mejores <br />
                      Servicios Para Tu <br />
                      Conexión
                    </h3>
                  </div>
                  {/* /.block-title-two */}
                  <p>
                    En <strong>X-Cel</strong>, garantizamos conectividad
                    confiable, rápida y segura con tecnología avanzada.
                    Diseñamos nuestros servicios para satisfacer todas tus
                    necesidades de comunicación.
                  </p>
                  <Link to="/" className="thm-btn">
                    Ver Todos los Servicios{" "}
                    <i className="fa fa-angle-double-right" />
                  </Link>
                  {/* /.thm-btn */}
                </div>
                {/* /.service-two__block */}
              </div>
              {/* /.my-auto */}
            </div>
            {/* /.col-lg-5 */}
            <div className="col-lg-7">
              <div className="service-two__box-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <div className="service-two__single js-tilt">
                      <div
                        className="service-two__single-image"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/service-bg-calls.jpg)",
                        }}
                      />
                      {/* /.service-two__single-image */}
                      <div className="service-two__single-inner">
                        <i className="fal fa-phone-alt" />
                        <h3>
                          <a to="/">Llamadas Ilimitadas</a>
                        </h3>
                        <p>
                          Habla con tus seres queridos o colegas sin límites,
                          con calidad de audio superior en cada llamada.
                        </p>
                        <Link
                          to="/"
                          className="service-two__link"
                        >
                          <i className="fa fa-long-arrow-alt-right" />
                        </Link>
                        {/* /.service-two__link */}
                      </div>
                      {/* /.service-two__single-inner */}
                    </div>
                    {/* /.service-two__single */}
                  </div>
                  {/* /.col-md-6 */}
                  <div className="col-md-6">
                    <div className="service-two__single js-tilt">
                      <div
                        className="service-two__single-image"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/service-bg-messages.jpg)",
                        }}
                      />
                      {/* /.service-two__single-image */}
                      <div className="service-two__single-inner">
                        <i className="fal fa-sms" />
                        <h3>
                          <a to="/">Mensajes SMS</a>
                        </h3>
                        <p>
                          Mantente conectado con mensajes rápidos y confiables,
                          con cobertura en todo el país.
                        </p>
                        <Link to="/" className="service-two__link">
                          <i className="fa fa-long-arrow-alt-right" />
                        </Link>
                        {/* /.service-two__link */}
                      </div>
                      {/* /.service-two__single-inner */}
                    </div>
                    {/* /.service-two__single */}
                  </div>
                  {/* /.col-md-6 */}
                  <div className="col-md-6">
                    <div className="service-two__single js-tilt">
                      <div
                        className="service-two__single-image"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/service-bg-internet.jpg)",
                        }}
                      />
                      {/* /.service-two__single-image */}
                      <div className="service-two__single-inner">
                        <i className="fal fa-wifi" />
                        <h3>
                          <a to="/">Internet Móvil</a>
                        </h3>
                        <p>
                          Navega a máxima velocidad con planes que se adaptan a
                          tu estilo de vida y necesidades.
                        </p>
                        <Link to="/" className="service-two__link">
                          <i className="fa fa-long-arrow-alt-right" />
                        </Link>
                        {/* /.service-two__link */}
                      </div>
                      {/* /.service-two__single-inner */}
                    </div>
                    {/* /.service-two__single */}
                  </div>
                  {/* /.col-md-6 */}
                  <div className="col-md-6">
                    <div className="service-two__single js-tilt">
                      <div
                        className="service-two__single-image"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/service-bg-coverage.jpg)",
                        }}
                      />
                      {/* /.service-two__single-image */}
                      <div className="service-two__single-inner">
                        <i className="fal fa-map-marker-alt" />
                        <h3>
                          <a to="/">Cobertura Extensa</a>
                        </h3>
                        <p>
                          Disfruta de cobertura confiable en las principales
                          ciudades y zonas rurales del país.
                        </p>
                        <Link to="/" className="service-two__link">
                          <i className="fa fa-long-arrow-alt-right" />
                        </Link>
                        {/* /.service-two__link */}
                      </div>
                      {/* /.service-two__single-inner */}
                    </div>
                    {/* /.service-two__single */}
                  </div>
                  {/* /.col-md-6 */}
                </div>
                {/* /.row */}
              </div>
              {/* /.service-two__box-wrap */}
            </div>
            {/* /.col-lg-7 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default Service;
