import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer_v2 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/theme.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(window).scrollTop(0);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <div className="site-footer-two  go-top">
        <img
          src={publicUrl + "assets/images/shapes/footer-shape-2-1.png"}
          alt={imgattr}
          className="site-footer-two__shape-1"
        />
        <img
          src={publicUrl + "assets/images/shapes/footer-shape-2-2.png"}
          alt={imgattr}
          className="site-footer-two__shape-2"
        />
        <div className="site-footer-two__upper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6">
                <div className="footer-widget footer-widget__about">
                  <Link to="/">
                    <img
                      src={publicUrl + "assets/images/logo-2-2.png"}
                      alt={imgattr}
                    />
                  </Link>
                  <p>
                    En <strong>X-Cel</strong>, trabajamos para mantenerte
                    conectado con servicios de calidad. Innovamos constantemente
                    para ofrecerte la mejor experiencia en llamadas, mensajes e
                    internet móvil.
                  </p>

                  <div className="footer-widget__social">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-google-plus-g" />
                    </a>
                    <a href="#">
                      <i className="fab fa-behance" />
                    </a>
                  </div>
                  {/* /.footer-widget__social */}
                </div>
                {/* /.footer-widget footer-widget__about */}
              </div>
              {/* /.col-lg-3 */}
              <div className="col-xl-3 col-lg-6">
                <div className="footer-widget footer-widget__post">
                  <h3 className="footer-widget__title"></h3>
                  {/* /.footer-widget__title */}
                  <div className="footer-widget__post-wrap">
                    <div className="footer-widget__post-single">
                      <div className="footer-widget__post-meta"></div>
                      {/* /.footer-widget__post-meta */}
                    </div>
                    {/* /.footer-widget__post-single */}
                    <div className="footer-widget__post-single">
                      <div className="footer-widget__post-meta"></div>
                      {/* /.footer-widget__post-meta */}
                    </div>
                    {/* /.footer-widget__post-single */}
                  </div>
                  {/* /.footer-widget__post-wrap */}
                </div>
                {/* /.footer-widget footer-widget__post */}
              </div>
              {/* /.col-lg-3 */}
              <div className="col-xl-3 col-lg-6">
                <div className="footer-widget footer-widget__links">
                  <h3 className="footer-widget__title">Accesos</h3>
                  {/* /.footer-widget__title */}
                  <ul className="footer-widget__links-list list-unstyled">
                    <li>
                      <Link to="/#">Acerca de</Link>
                    </li>
                    <li>
                      <Link to="/#">Servicios</Link>
                    </li>
                    <li>
                      <Link to="/#">Politica de Privacidad</Link>
                    </li>
                    <li>
                      <Link to="/#">Terminos y condiciones</Link>
                    </li>
                  </ul>
                  {/* /.footer-widget__links-list */}
                </div>
                {/* /.footer-widget footer-widget__post */}
              </div>
              {/* /.col-lg-3 */}
              <div className="col-xl-3 col-lg-6">
                <div className="footer-widget footer-widget__contact">
                  <h3 className="footer-widget__title">Newsletters</h3>
                  {/* /.footer-widget__title */}
                  <p>
                    Suscribete para tener noticias y actualizaciones de nuestros
                    servicios.
                  </p>
                  <form action="#" className="footer__mc-form">
                    <input
                      type="text"
                      name="s"
                      placeholder="Enter Your Email"
                    />
                    <button type="submit">
                      <i className="fa fa-long-arrow-alt-right" />
                    </button>
                  </form>
                  {/* /.footer__mc-form */}
                  <ul className="footer-widget__contact-list list-unstyled">
                    <li>
                      <i className="far fa-envelope" />
                      <a href="mailto:support@gmail.com">soporte@xcel.com.mx</a>
                    </li>
                    <li>
                      <i className="far fa-phone" />
                      <a href="tel:+55 1234 1234">+55 1234 1234 </a>
                    </li>
                  </ul>
                  {/* /.footer-widget__contact-list */}
                </div>
                {/* /.footer-widget footer-widget__post */}
              </div>
              {/* /.col-lg-3 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* /.site-footer-two__upper */}
        <div className="site-footer-two__bottom">
          <div className="container">
            <p>
              Copy@2024 <a href="#">X-cel</a>. Todos los derechos reservados.
            </p>
          </div>
          {/* /.container */}
        </div>
        {/* /.site-footer-two__bottom */}
      </div>
    );
  }
}

export default Footer_v2;
