import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Contact extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11216481.857424822!2d-101.07271282885044!3d23.628566860212675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1733623168088!5m2!1ses!2smx"
          className="google-map__contact"
          allowFullScreen
        />
      </div>
    );
  }
}

export default Contact;
